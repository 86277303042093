
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { PersonView } from '@/components/forms/view/PersonView'
  import { Person } from '@/entities/persons'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import AddressAlignment from '@/components/person/AddressAlignment.vue'
  import { plainToInstance } from 'class-transformer'
  import { isEmpty } from '@/utils/general'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { ConsultRutInterface } from '@/store/persons/state'
  import BankDataAlignment from '@/components/person/BankDataAlignment.vue'
  import { Address, bankDataFull } from '@/store/persons/person'

@Component({
  components: { BankDataAlignment, GAlert, AddressAlignment, GDatePicker },
  methods: {},
})
  export default class CompanyFields extends PersonView {
  @Prop({ type: Object, default: () => ({}) }) value!: Person
  @Prop({ type: String, default: null }) type!: string

  disabled = false
  person = plainToInstance(Person, {})
  errorMessage = {
    rut: '',
  }

  formData = {
    rut: '',
    socialReason: '',
    alias: '',
    companyType: null,
    webSite: '',
    birthDate: '',
    contactPerson: [],
    charterAttachment: [],
    currentValidityAttachment: [],
    taxInformation: [],
    bankData: [],
    extractAttachment: [],
    addresses: [],
    officialPublishAttachment: [],
  }

  fields = {
    companyType: {
      items: [],
    },
  }

  alert = {
    open: false,
    title: '',
    subtitle: '',
  }

  alertFound = {
    open: false,
    title: '',
    subtitle: '',
  }

  alertRemoveDirection = {
    open: false,
    title: '',
    subtitle: '',
    address: null,
  }

  alertRemoveBankData = {
    open: false,
    title: '',
    subtitle: '',
    bankData: null,
  }

  // Methods
  async mounted () {
    this.fields.companyType.items = await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
    })

    this.setTheBackup()
  }

  setTheBackup () {
    const { backup } = this
    if (!backup) return
    const companyForm = 'companyForm' in backup && backup.companyForm

    if (companyForm) {
      this.formData = backup.companyForm
    }
  }

  async searchRut (rut: string) {
    if (!rut?.length || rut?.length < 8) return null

    this.findPerson({ rut, type: this.type })
  }

  async send () {
    if (!this.$refs.form.validate()) return
    this.loadingForm = true
    const { type, formData } = this

    await this.createUpdatePerson(null, formData, type)

    await this.close()
  }

  initValues () {
    this.initCompany()
    this.setFoundPerson(null)
    this.setRutInfo(null)
  }

  initCompany () {
    this.formData = {
      rut: '',
      socialReason: '',
      alias: '',
      companyType: '',
      webSite: '',
      birthDate: '',
      contactPerson: [],
      charterAttachment: [],
      currentValidityAttachment: [],
      taxInformation: [],
      bankData: [],
      extractAttachment: [],
      addresses: [],
      officialPublishAttachment: [],
    }
    this.disabled = false
  }

  async openBankData (): Promise<void> {
    const { person, value, formData, type } = this

    let id = value?.id || person?.id

    const newPerson = await this.createUpdatePerson(id, formData, type)

    await this.$router.replace({ params: { id: newPerson?.id.toString() } }).catch(() => {
    })

    this.saveBackup()
    id = newPerson?.id || id
    if (id) {
      await this.$router.push({
        name: 'person-bank-data',
        params: {
          model: 'Person',
          uid: id.toString(),
          id: 'create',
        },
      })
    }
  }

  async openDirectionData (): Promise<void> {
    const { person, value, formData, type } = this

    let id = value?.id || person?.id

    const newPerson = await this.createUpdatePerson(id, formData, type)
    await this.$router.replace({ params: { id: newPerson?.id.toString() } }).catch(() => {
    })
    id = newPerson?.id || id
    this.saveBackup()
    if (id) {
      await this.$router.push({
        name: 'person-address-data',
        params: {
          model: 'Person',
          uid: id.toString(),
          id: 'create',
        },
      })
    }
  }

  removeDirection (direction: Address): void {
    this.alertRemoveDirection = {
      open: true,
      title: '¿Estás seguro de que quieres desvincular esta dirección?',
      subtitle: `${direction.streetName} ${direction.streetNumber}, ${direction.cityName}, ${direction.regionAbbreviation}/${direction.addressType}`,
      address: direction,
    }
  }

  async confirmRemoveDirection () {
    this.alertRemoveDirection.open = false
    await this.addressUpdateStatus(this.alertRemoveDirection.address.idPersonAddress)
    this.formData.addresses = await this.getAddressFormatted(this.personEntity)
    this.$emit('update')
  }

  removeBankData (bankData: bankDataFull): void {
    this.alertRemoveBankData = {
      open: true,
      title: '¿Estás seguro de que quieres desvincular este dato bancario?',
      subtitle: `${bankData.accountNumber}/${bankData.bank}/${bankData.accountType}`,
      bankData,
    }
  }

  async confirmRemoveBankData () {
    this.alertRemoveBankData.open = false
    await this.updateAccountState(this.alertRemoveBankData.bankData.idPersonAccount)
    this.$emit('update')
  }

  confirmChange () {
    this.alert = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.$emit('change-type')
  }

  confirm () {
    this.alertFound = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.formData.rut = this.rutInfo.rut
    this.formData.socialReason = this.rutInfo.otherNames
  }

  cancelChange () {
    this.alert = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.initCompany()
  }

  saveBackup () {
    const { backup, formData } = this
    if (backup) {
      backup.companyForm = formData
      this.setBackup(backup)
    } else {
      this.setBackup({
        companyForm: { ...formData },
      })
    }
  }

  emitEmbed (newPerson) {
    const { typeInfo, backup } = this

    if (backup) {
      backup[typeInfo] = newPerson
      this.setBackup(backup)
    } else {
      this.setBackup({ [typeInfo]: newPerson })
    }

    this.embedPerson({ type: typeInfo, person: newPerson })
  }

  // Getters
  get personCreatable (): boolean {
    const { formData, errorMessage: { rut } } = this
    return (
      !isEmpty(formData.rut) &&
      !isEmpty(formData.socialReason) &&
      !isEmpty(formData.alias) &&
      !isEmpty(formData.companyType) &&
      isEmpty(rut)
    )
  }

  // Watchers
  @Watch('value', { immediate: true, deep: true })
  async onValueChanged (person: Person) {
    if (!person) return

    if (person.uid) {
      this.disabled = true
    }

    this.formData.rut = person.uid
    this.formData.alias = person.alias
    this.formData.socialReason = person.fullName
    this.formData.webSite = person.webSite
    this.formData.birthDate = person?.birthDate?.format('YYYY-MM-DD')
    this.formData.companyType = person?.companyType?.id
    this.formData.addresses = await this.getAddressFormatted(person)
    this.formData.bankData = this.getBankDataFormatted(person)
  }

  getBankDataFormatted (person) {
    return person?.accounts?.map(account => {
      return {
        idPersonAccount: account.id,
        accountNumber: account.bankData.accountNumber,
        bank: account.bankData.bank.alias,
        accountType: account.bankData.accountType.description,
      }
    })
  }

  async getAddressFormatted (person) {
    if (!person?.id) return []
    const addresses = await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { _and: [{ id_person: { _eq: person.id } }, { active: { _eq: true } }] },
      force: true,
    })

    return addresses?.map(personAddress => {
      return {
        idAddress: personAddress.address.id,
        streetName: personAddress.address.streetName,
        streetNumber: personAddress.address.streetNumber,
        idCity: personAddress.address.city.id,
        idPersonAddress: personAddress.id,
        cityName: personAddress.address.city.name,
        idRegion: personAddress.address.city.region.id,
        regionAbbreviation: personAddress.address.city.region.abbreviation,
        addressType: personAddress.type.description,
      }
    }) || []
  }

  validNames (val) {
    if (!val) return false
    const keys = ['firstName', 'firstLastName', 'secondName', 'secondLastName', 'otherNames']
    const valid = keys.every(key => val[key] !== null && val[key] !== undefined)

    return !valid
  }

  @Watch('rutInfo', { immediate: true, deep: true })
  onRutInfoChanged (val: ConsultRutInterface) {
    if (!val || this.validNames(val)) {
      this.formData.rut = val?.rut
      return
    }
    const { backup } = this
    if ('companyForm' in backup && backup.companyForm) {
      return
    }

    if (isEmpty(val.otherNames)) {
      this.alert = {
        open: true,
        title: `Segun nuestro sistema el rut ${val.rut} es una persona de tipo natural de nombre`,
        subtitle: `${val.firstName} ${val.firstLastName} ${val.secondName} ${val.secondLastName}`,
      }
    } else {
      this.alertFound = {
        open: true,
        title: `Segun nuestro sistema el rut ${val.rut} pertenece a:`,
        subtitle: `${val.otherNames}`,
      }
    }
  }
  }
