
  import { Component, Prop } from 'vue-property-decorator'
  import { bankDataFull } from '@/store/persons/person'
  import {
    truncateLongText,
  } from '@/utils/general'
  import { BaseCustomForm } from '@/components/person/BaseAlignment'

@Component
  export default class BankDataAlignment extends BaseCustomForm {
  @Prop({
    type: Array,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    default: () => [],
  })
  bankData!: Array<bankDataFull>;

  truncateText (text: string): string {
    return truncateLongText(text, 40)
  }
  }
