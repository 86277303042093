import { Component } from 'vue-property-decorator'
import { BaseCustomForm } from '@/components/person/BasicCustomForm'
import { fixDate } from '@/utils/general'

@Component
export class PersonView extends BaseCustomForm {
  async searchPhone (phone) {
    if (!phone?.length || phone?.length < 2) return null

    // eslint-disable-next-line no-return-await
    return await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { id: { _eq: phone } },
      force: true,
    })
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  async createUpdatePerson (id, data, personType: string) {
    const { backup } = this
    const { rut, socialReason, alias, webSite, birthDate, companyType, typeInfo } = data

    const existPerson = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { uid: { _eq: rut } },
      force: true,
    })

    const fields = {
      id: existPerson?.[0]?.id || id,
      uid: rut,
      company_name: socialReason,
      id_company_type: companyType,
      alias,
      web_site: webSite,
      birth_date: birthDate && fixDate(birthDate).length ? fixDate(birthDate) : null,
      name_person_type: personType,
    }
    if (!fields?.id) delete fields.id

    const person = await this.pushData({
      model: 'Person',
      fields,
    })
    if (backup) {
      backup[typeInfo] = data
      this.setBackup(backup)
    } else {
      this.setBackup({ [typeInfo]: data })
    }
    return person
  }
}
